import styled, { css } from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'

export const ImageSlider = styled.div`
  display: flex;
  gap: 12px;
`

export const InformationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.xl.max} {
    width: 100%;
    max-width: 850px;
    margin: 0px auto;
  }
`

export const ProductContainer = styled(Container)`
  max-width: calc(1136px + 64px);

  gap: 64px;
  justify-content: space-between;

  ${media.xxl.max} {
    gap: 24px;
  }
  ${media.xl.max} {
    flex-direction: column;
  }
  ${media.lg.max} {
    padding: 32px 16px;
  }
  ${media.sm.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
`

export const FlatContainer = styled.div`
  width: 100%;

  margin: 0px auto;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.lg.max} {
    width: fit-content;
    min-width: 0px;
  }
  ${media.sm.max} {
    padding: 0px 16px;
  }
`

export const FlatVisualization = styled.div`
  max-width: 100%;
  aspect-ratio: 768/633;

  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px solid ${({ theme }) => theme.colors.gray05};

  ${media.xxl.max} {
    aspect-ratio: 768/600;
  }
  ${media.sm.max} {
    width: 100%;
    margin: 0px auto;
  }
`

export const VisualizationOptions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray05};

  ${media.sm.max} {
    flex-direction: column;
    gap: 16px;
  }
`

export const ImageSlide = styled.div<{ selected?: boolean }>`
  width: 64px;
  height: 64px;

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.primary};
    `}
`
