import styled from 'styled-components'
import media from 'styles/media'

export const SurfaceAreaText = styled.div`
  display: flex;
  flex-direction: column;
`

export const BuildingIcon = styled.div`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green0};
`

export const InformationsDetails = styled.div`
  width: 100%;

  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.gray05};

  display: flex;

  ${media.md.max} {
    margin: 0px auto;

    flex-direction: column;
    height: fit-content;
  }
`

export const DetilesItem = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || '12px 16px'};
  border-right: 1px solid ${({ theme }) => theme.colors.gray05};
  display: flex;
  flex-direction: column;

  flex: 1 1;

  &:first-child {
    flex-direction: row;
    gap: 16px;
  }

  &:last-child {
    border: none;
    ${media.lg.max} {
      border-top: 1px solid ${({ theme }) => theme.colors.gray05};
    }
  }

  ${media.md.max} {
    border-right: none;
    border-top: 1px solid ${({ theme }) => theme.colors.gray05};

    ${SurfaceAreaText}:first-child {
      flex-direction: row-reverse;
      justify-content: center;
      gap: 16px;
    }

    & p {
      flex: 1;
    }
  }
  ${media.xs.max} {
    min-width: 0px;
  }
`
