import React from 'react'

import { Image } from 'components/atoms/Image'
// import { Button } from 'components/atoms/Button'
import type { Flat } from 'types/domain'

import { getStatusName } from 'utils/parseFlat'
import useFlatContext from 'hooks/useFlatContext'
import {
  FlatContainer,
  FlatVisualization,
  // ImageSlide,
  // ImageSlider,
  InformationsContainer,
  ProductContainer,
  // VisualizationOptions,
} from './FlatView.styles'

import { FlatInformations, FlatDetails } from './FlatViewComponents'
import { Contact } from './Contact'

type InformationType = {
  label: string
  value: string
}

type FlatViewProps = {
  flat: Flat
  creditHeading: string
  flatPrice?: number
}

export const FlatView: React.FC<FlatViewProps> = ({
  flat,
  creditHeading,
  flatPrice,
}) => {
  const { isFavourite, toggleFavourite } = useFlatContext()

  const flatInformations: InformationType[] = [
    {
      label: 'Status',
      value: `${getStatusName(flat.status)}`,
    },
    {
      label: 'Powierzchnia',
      value: `${flat.area}`,
    },
    {
      label: 'Liczba pokoi',
      value: `${flat.rooms}`,
    },
    {
      label: 'Piętro',
      value: `${flat.floor}`,
    },

    // {
    //   label: 'Dodatkowo',
    //   value: 'Balkon', // TODO: Get data from API
    // },
  ]

  return (
    <ProductContainer flexRow padding={[32, 32, 64]} margin={[0, 'auto']}>
      <FlatContainer>
        <FlatInformations
          flatName={`Mieszkanie numer ${flat.number}`}
          pdfLink={flat.pdf ?? ''}
          isFavourite={isFavourite(flat)}
          toggleFavourite={() => toggleFavourite(flat)}
          flatPrice={flatPrice}
        />

        <InformationsContainer>
          <FlatDetails flatInformations={flatInformations} />

          <FlatVisualization>
            {/* <div style={{ width: '100%', height: '100%' }}> */}
            <Image
              objectFit="contain"
              src={flat.img2D}
              loading="eager"
              alt="Flat Image"
            />
            {/* </div> */}
            {/* <VisualizationOptions> */}
            {/* <ImageSlider> */}
            {/* <ImageSlide selected> */}
            {/* <div style={{ width: '100%', height: '100%' }}> */}
            {/* <Image src={flat.img2D} alt="Flat Iamge" /> */}
            {/* </div> */}
            {/* </ImageSlide> */}
            {/* <ImageSlide> */}
            {/* <div style={{ width: '100%', height: '100%' }}> */}
            {/* TODO: Get new Slides from CRM */}
            {/* <Image src={flat.img2D} alt="Flat Iamge" /> */}
            {/* </div> */}
            {/* </ImageSlide> */}
            {/* <ImageSlide> */}
            {/* <div style={{ width: '100%', height: '100%' }}> */}
            {/* <Image src={flat.img2D} alt="Flat Iamge" /> */}
            {/* </div> */}
            {/* </ImageSlide> */}
            {/* </ImageSlider> */}
            {/* <Button TextButton2 secondary margin="auto 0px"> */}
            {/* Wirtualny spacer */}
            {/* </Button> */}
            {/* </VisualizationOptions> */}
          </FlatVisualization>
        </InformationsContainer>
      </FlatContainer>

      <Contact heading={creditHeading} flatNumber={flat.number} />
    </ProductContainer>
  )
}
