import styled from 'styled-components'
import media from 'styles/media'

import { H3Bold } from 'components/atoms/Typography'
import { IconButton } from 'components/atoms/Button'

export const FlatInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const InformationsIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  cursor: pointer;
`

export const InformationsHeading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  flex-direction: column;
  gap: 16px;

  ${H3Bold} {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.gray50};
  }
`

export const InformationsIcons = styled.div`
  display: flex;
  gap: 24px;

  ${media.sm.max} {
    flex-direction: column;
    gap: 8px;
  }
`

export const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  & svg {
    width: 14px;
    height: 14px;
  }
`
