import React, { useContext, useEffect } from 'react'
import { PageProps, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import media from 'styles/media'

import { Flat } from 'types/domain'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import { Container } from 'components/atoms/Container'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { SimilarFlats } from 'components/organisms/Product/SimilarFlats'
import { FlatView } from 'components/organisms/Product/FlatView'
import { FlatContext } from 'contexts/FlatContext'

import getSimilarFlats from 'utils/getSimilarFlats'

const FlatViewWrapper = styled.section`
  margin: 97px auto 0px auto;
  padding: 8px 0;

  ${media.xxl.max} {
    padding: 12px 0;
  }
  ${media.lg.max} {
    padding: 8px 0;
  }
  ${media.sm.max} {
    margin-top: 57px;
  }
`

const BreadcrumbsContainer = styled(Container)`
  max-width: calc(1136px + 48px);

  ${media.sm.max} {
    padding: 0px;
  }
`

const FlatPage: React.FC<PageProps<Queries.MieszkaniePageQuery, Flat>> = ({
  // data,
  pageContext,
}) => {
  const data = useStaticQuery(graphql`
    query MieszkaniePage {
      wpPage(slug: { regex: "/mieszkanie/" }) {
        flat {
          flatCreditsTitle
          flatSimilarFlatsTitle
          flatSimilarFlatsImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1400)
              }
            }
          }
        }
        seo {
          ...WpSEO
        }
      }
    }
  `)

  const PAGE = data.wpPage?.flat
  const PAGE_SEO = data.wpPage?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const { flats, promotionFlatsWithPrice } = useContext(FlatContext)

  const similarFlats = getSimilarFlats(pageContext, flats, 5)

  useEffect(() => {
    /* eslint-disable */
    // @ts-ignore
    ylData = window.ylData = window.ylData || []
    // @ts-ignore
    ylData.push({ product: { products: [pageContext.urlID] } })
    /* eslint-enable */
  }, [])

  const flatPrice = promotionFlatsWithPrice.find(
    (flat) => flat.flatId === pageContext.urlID
  )?.flatPrice

  return (
    <Layout>
      <Seo
        title={`Mieszkanie ${pageContext.number}`}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <FlatViewWrapper>
        <BreadcrumbsContainer margin={[0, 'auto']} padding={[0, 24]}>
          <Breadcrumbs
            crumbs={[{ name: `Mieszkanie nr ${pageContext.number}` }]}
          />
        </BreadcrumbsContainer>

        <FlatView
          flat={pageContext}
          flatPrice={flatPrice}
          creditHeading={PAGE.flatCreditsTitle!}
        />
      </FlatViewWrapper>

      {similarFlats.length > 0 ? (
        <SimilarFlats
          heading={PAGE.flatSimilarFlatsTitle!}
          image={{
            src: PAGE.flatSimilarFlatsImg?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.flatSimilarFlatsImg?.altText!,
          }}
          flats={similarFlats}
        />
      ) : null}
    </Layout>
  )
}

export default FlatPage
