import React from 'react'

import { ReactComponent as Building } from 'assets/icons/building.svg'

import { TextButton2, Uppercase } from 'components/atoms/Typography'
import {
  BuildingIcon,
  DetilesItem,
  InformationsDetails,
  SurfaceAreaText,
} from './FlatDetiles.styles'

type InformationType = {
  label: string
  value: string
}

export const FlatDetails = ({
  flatInformations,
}: {
  flatInformations: Array<InformationType>
}) => {
  return (
    <InformationsDetails>
      {flatInformations.map(({ label, value }, index) => (
        <DetilesItem
          key={label}
          padding={index === 0 ? '12px 24px' : undefined}
        >
          {index === 0 && (
            <BuildingIcon>
              <Building />
            </BuildingIcon>
          )}
          <SurfaceAreaText>
            <TextButton2>
              <Uppercase>
                {/* TODO: change ² */}
                {value} {label === 'Powierzchnia' ? 'M²' : ''}
              </Uppercase>
            </TextButton2>
            <TextButton2 color="gray10">{label}</TextButton2>
          </SurfaceAreaText>
        </DetilesItem>
      ))}
    </InformationsDetails>
  )
}
