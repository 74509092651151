import React from 'react'

import { ContactForm } from 'components/organisms/Product/FlatView/Contact/ContactFrom'

import { Button, ButtonLine } from 'components/atoms/Button'
import { Link, Text } from 'components/atoms/Typography'
import {
  FormResponseContainer,
  ResponseHeader,
} from 'components/organisms/Contact/Form/Form.styles'
import { ContactContainer, ContactQuestion } from './Contact.styles'

type Props = {
  heading: string
  flatNumber: string
}

export const Contact: React.FC<Props> = ({ heading, flatNumber }) => {
  const [formInfo, setFormInfo] = React.useState({
    text: '',
    error: false,
  })

  return (
    <ContactContainer>
      {formInfo.text === '' ? (
        <ContactForm
          formInfo={formInfo}
          setFormInfo={setFormInfo}
          flatNumber={flatNumber}
        />
      ) : (
        <FormResponseContainer>
          <ResponseHeader isError={formInfo.error}>
            {formInfo.error ? 'Error' : `Dziękujemy\nza przesłanie\nformularza`}
          </ResponseHeader>
          <Text fontWeight={300} fontSize={14} fontFamily="roboto">
            {formInfo.text}
          </Text>
        </FormResponseContainer>
      )}

      <ContactQuestion>
        <Text
          fontSize={24}
          fontWeight={300}
          lineHeight={30}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Button noMaxWidth TextButton2 contrast>
          <Link to="/kontakt">
            <ButtonLine>Napisz do nas</ButtonLine>
          </Link>
        </Button>
      </ContactQuestion>
    </ContactContainer>
  )
}
