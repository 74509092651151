import React from 'react'

// import { ReactComponent as Heart } from 'assets/icons/heart.svg'
import { ReactComponent as Download } from 'assets/icons/download.svg'

import { H2Light, TextBody2, H3Bold } from 'components/atoms/Typography'
import { CircularIcon } from 'components/atoms/CircularIcon'
import { Heart } from 'components/modules/Heart'
import {
  FlatInformation,
  InformationsHeading,
  InformationsIcon,
  InformationsIcons,
} from './FlatInformations.styles'

export const FlatInformations: React.FC<{
  pdfLink: string
  flatName: string
  isFavourite: boolean
  toggleFavourite: () => void
  flatPrice?: number
}> = ({ pdfLink, flatName, isFavourite, toggleFavourite, flatPrice }) => {
  return (
    <FlatInformation>
      <InformationsHeading>
        <div>
          <H2Light>{flatName}</H2Light>
          {flatPrice && <H3Bold>{flatPrice.toLocaleString()} zł</H3Bold>}
        </div>
        <InformationsIcons>
          <InformationsIcon onClick={toggleFavourite}>
            <Heart size="tiny" fill={isFavourite} />
            <TextBody2>
              {isFavourite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
            </TextBody2>
          </InformationsIcon>
          <InformationsIcon
            as="a"
            href={pdfLink}
            target="_blank"
            onClick={() => {
              /* eslint-disable */
              // @ts-ignore
              ylData = window.ylData = window.ylData || []
              // @ts-ignore
              ylData.push({ event: { eventName: 'pobranie-pdf' } })
              /* eslint-enable */
            }}
          >
            <CircularIcon icon={Download} size="tiny" />
            <TextBody2>Pobierz kartę PDF</TextBody2>
          </InformationsIcon>
        </InformationsIcons>
      </InformationsHeading>
    </FlatInformation>
  )
}
