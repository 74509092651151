const getSimilarFlats = (
  flat: any,
  flats: any[],
  count: number | undefined = undefined
) => {
  const result = [...flats]
    .filter((f) => {
      let similar = true
      // if (flat.buildingName !== f.buildingName) {
      //   similar = false
      // }

      const areaDifference = Math.abs(f.area - flat.area)
      if (areaDifference > 7) {
        similar = false
      }

      return similar && [1, 2, 7].includes(f.statusId) && f.id != flat.id
    })
    .sort((a, b) => {
      const aAreaDifference = Math.abs(a.area - flat.area)
      const bAreaDifference = Math.abs(b.area - flat.area)

      const aFloorDifference = Math.abs(a.floor - flat.floor)
      const bFloorDifference = Math.abs(b.floor - flat.floor)

      return (
        (aAreaDifference - bAreaDifference) * 1 +
        (aFloorDifference - bFloorDifference) * 0.5
      )
    })

  if (count) {
    return [...result.slice(0, count)]
  }

  return result
}

export default getSimilarFlats
