import React from 'react'

import { ReactComponent as Building } from 'assets/icons/building.svg'
import { ReactComponent as Surface } from 'assets/icons/surface.svg'
import { ReactComponent as Steps } from 'assets/icons/steps.svg'

import { Flat } from 'types/domain'

import { Image } from 'components/atoms/Image'
import { Button } from 'components/atoms/Button'
import { ColoredText, H3Bold, Link, Text } from 'components/atoms/Typography'
import { Heart } from 'components/modules/Heart'
import useFlatContext from 'hooks/useFlatContext'

import {
  FlatPreview,
  FlatContainer,
  AvailabilityText,
  FlatInformations,
  FlatInformationsDetails,
  FlatInformationsItem,
  ImageWraper,
  FlatHeart,
  StyledTextBody1,
  FlatPrice,
} from './FlatCard.styles'

type FlatCardProps = {
  flatData: Flat
}

export const FlatCard: React.FC<FlatCardProps> = ({ flatData }) => {
  const { number, status, rooms, area, urlID, floor, img2D } = flatData
  const { toggleFavourite, isFavourite } = useFlatContext()

  const onHeartClick = () => toggleFavourite(flatData)

  return (
    <FlatContainer>
      {(status !== 'sold' || isFavourite(flatData)) && (
        <FlatHeart>
          <Heart fill={isFavourite(flatData)} onClick={onHeartClick} />
        </FlatHeart>
      )}

      <FlatPreview>
        {status === 'sold' && (
          <AvailabilityText status="sold">
            <Text fontSize={10} lineHeight={16} color="white">
              Niedostępne
            </Text>
          </AvailabilityText>
        )}
        {status === 'reserved' && (
          <AvailabilityText status="reserved">
            <Text fontSize={10} lineHeight={16} color="white">
              Zarezerowowane
            </Text>
          </AvailabilityText>
        )}
        <ImageWraper isAvailable={status !== 'sold'}>
          <Image src={img2D} alt="Rzut mieszkania" objectFit="contain" />
        </ImageWraper>
      </FlatPreview>
      <FlatInformations available={status !== 'sold'}>
        <H3Bold align="center">
          Mieszkanie
          {status === 'available' && (
            <ColoredText color="primary"> nr {number}</ColoredText>
          )}
          {status === 'reserved' && (
            <ColoredText color="reserved"> nr {number}</ColoredText>
          )}
          {status === 'sold' && (
            <ColoredText color="sold"> nr {number}</ColoredText>
          )}
        </H3Bold>
        {flatData.flatPrice ? (
          <FlatPrice>{flatData.flatPrice.toLocaleString()} zł</FlatPrice>
        ) : null}
        <FlatInformationsDetails>
          <FlatInformationsItem>
            <Surface />
            <StyledTextBody1 fontSize={14} fontFamily="roboto">
              {area} m
            </StyledTextBody1>
          </FlatInformationsItem>
          <FlatInformationsItem>
            <Building />
            <StyledTextBody1 fontSize={14} fontFamily="roboto">
              {/* eslint-disable-next-line no-nested-ternary */}
              {rooms} {rooms === 1 ? 'pokój' : rooms < 5 ? 'pokoje' : 'pokoi'}
            </StyledTextBody1>
          </FlatInformationsItem>
          <FlatInformationsItem>
            <Steps />
            <StyledTextBody1 fontSize={14} fontFamily="roboto">
              {floor} piętro
            </StyledTextBody1>
          </FlatInformationsItem>
        </FlatInformationsDetails>
      </FlatInformations>
      <Link fillWidth to={status === 'sold' ? '' : `/mieszkanie/${urlID}`}>
        <Button noMaxWidth TextButton2 secondary disabled={status === 'sold'}>
          Zobacz mieszkanie
        </Button>
      </Link>
    </FlatContainer>
  )
}
