import styled, { css } from 'styled-components'
import media from 'styles/media'
import { ImageWrapper as AtomImageWrapper } from 'components/atoms/Image/Image.style'
import { Flat } from 'types/domain'
import { TextBody1 } from 'components/atoms/Typography'

export const ImageWraper = styled.div<{ isAvailable: boolean }>`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isAvailable }) =>
    !isAvailable &&
    css`
      opacity: 0.5;
    `}

  img {
    margin-bottom: 0;
  }
  ${AtomImageWrapper} {
    height: 100%;
  }
`

export const StyledTextBody1 = styled(TextBody1)`
  white-space: nowrap;
`

export const FlatInformationsDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  justify-content: center;
`

export const FlatHeart = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
`

export const FlatPreview = styled.div`
  isolation: isolate;
  position: relative;
  width: 100%;
  height: 170px;
`

export const FlatInformationsItem = styled.div<{ available?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  flex: 1;

  & svg {
    width: 20px;
    height: 20px;
  }
`

export const ImageGrayFilter = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;

  z-index: 10;
  background-color: red;
`

export const FlatInformations = styled.div<{ available?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 19px;

  p {
    align-self: center;
  }

  ${({ available }) =>
    available === false &&
    css`
      p,
      span {
        &:first-child {
          color: #929292;
        }
        color: ${({ theme }) => theme.colors.gray10};
      }

      svg {
        path {
          stroke: #969fa8;
        }
      }
    `}
`

export const AvailabilityText = styled.div<{ status: Flat['status'] }>`
  position: absolute;
  top: 0px;
  left: 0px;

  z-index: 15;

  background-color: ${({ theme, status }) => theme.colors[status]};

  border-radius: 99px;
  padding: 4px 10px;

  p {
    text-transform: uppercase;
  }
`

export const FlatContainer = styled.div`
  height: 100%;
  width: 352px;
  position: relative;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.gray05};

  padding: 24px;

  ${media.sm.max} {
    width: 288px;
    height: fit-content;

    gap: 24px;

    padding: 16px;

    & button {
      padding: 15px 25px;
    }
  }
`

export const FlatPrice = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray50};
`
