import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { H2Light } from 'components/atoms/Typography'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import { Flat } from 'types/domain'
import { ImageType } from 'types/image'

import { FlatCard } from 'components/modules/FlatCard'

import { LazyImage } from 'components/atoms/Image'

import {
  BackgroundImageDarken,
  FlatsContainer,
  OtherFlastsContent,
  OtherFlats,
  OtherFlatsBackground,
  OtherFlatsBackgroundWraper,
} from './SimilarFlats.styles'

type Props = {
  heading: string
  image: ImageType
  flats: Array<Flat>
}

export const SimilarFlats: React.FC<Props> = ({ heading, image, flats }) => {
  return (
    <OtherFlats flexColumn padding={[32, 40, 64]} margin={[0, 'auto']}>
      <OtherFlatsBackgroundWraper>
        <OtherFlatsBackground>
          <LazyImage src={image.src} alt={image.alt!} fill objectFit="cover" />
          <BackgroundImageDarken />
        </OtherFlatsBackground>
      </OtherFlatsBackgroundWraper>
      <OtherFlastsContent>
        <H2Light color="white" dangerouslySetInnerHTML={{ __html: heading }} />

        <FlatsContainer>
          <Swiper
            slidesPerView="auto"
            spaceBetween={0}
            grabCursor
            pagination={{ clickable: true }}
            mousewheel={{ releaseOnEdges: true }}
            initialSlide={0}
          >
            {flats.map((flat: Flat) => (
              <SwiperSlide>
                <FlatCard flatData={flat} />
              </SwiperSlide>
            ))}
          </Swiper>
        </FlatsContainer>
      </OtherFlastsContent>
    </OtherFlats>
  )
}
