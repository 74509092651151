import styled from 'styled-components'
import media from 'styles/media'

export const ContactContainer = styled.div`
  max-width: 360px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.xl.max} {
    max-width: 850px;
    margin: 0px auto;
    flex-direction: row;
  }
  ${media.lg.max} {
    max-width: none;
    width: fit-content;
    margin: 0px auto;
  }
  ${media.md.max} {
    flex-direction: column;
  }
`

export const ContactQuestion = styled.div`
  padding: 32px 40px;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;

  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.lg.max} {
    padding: 32px 24px;
  }
  ${media.sm.max} {
    width: calc(100% - 32px);
    margin: 0px auto;
    padding: 24px 16px;
  }
`
