import styled from 'styled-components'
import media from 'styles/media'

import { Container } from 'components/atoms/Container'

export const OtherFlats = styled(Container)`
  max-width: 1440px;

  ${media.lg.max} {
    padding: 32px 16px;
  }
  ${media.md.max} {
    padding: 32px 0px;
  }
`

export const OtherFlatsBackgroundWraper = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
`

export const OtherFlatsBackground = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 360px;

  & img {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 45.66%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 30.43%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(0deg, #000 0%, #000 100%), url(<path-to-image>),
      lightgray 50% / cover no-repeat;
  }
`

export const BackgroundImageDarken = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;
  min-height: 330px;

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 21.47%,
      rgba(0, 0, 0, 0) 47.01%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 30.43%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));

  z-index: 8;
`

export const OtherFlastsContent = styled.div`
  padding: 64px 80px 0px 80px;

  z-index: 10;

  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.lg.max} {
    padding: 32px 40px 0px 40px;
  }
  ${media.md.max} {
    padding: 32px 0px 0px 16px;
  }
  ${media.xs.max} {
    gap: 24px;
  }
`

export const FlatsContainer = styled.div`
  width: 100%;

  .swiper-container {
    overflow: visible;
  }

  & .swiper-slide {
    width: fit-content;

    padding-left: 0px;
    padding-right: 16px;
  }
`
